<template>
  <survey-detail
      :is-menu-expanded="isMenuExpanded"
      :models="models"
      :breadcrumbs="breadcrumbs"
      :is-printing="isPrinting"
      :is-animated="isAnimated"
      @print="printPage"
  />
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, reactive, onMounted, nextTick
} from 'vue';
import axios from 'axios';
import store from '@/store';
import {SurveyDetail} from '@/components/04_Templates/Client';
import {useRoute} from 'vue-router';
import html2pdf from 'html2pdf.js';
import printPageHTML from '@/assets/html/print.html';

export default defineComponent({
    inheritAttrs: false,
    components: {SurveyDetail},
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const $route = useRoute();
        const isPrinting = ref(false);
        const isAnimated = ref(false);

        /**
         * breadcrumbs - パンくずリスト
         * @type Array<Object>
         */
        const breadcrumbs = [
            {
                label: 'アンケート一覧',
                to: {name: 'ClientSurveys'},
                beforeIcon: 'Questionary'
            }, {
                label: 'アンケート結果',
                to: {name: 'ClientSurveysDetail'}
            }
        ];

        const {surveyId} = $route.params;
        const models = reactive({
            isLoading: false,
            infomation: [],
            genders: [],
            eras: [],
            sense_codes: [],
            sense_code_unanswered_count: 0,
            prefectures: [],
            prefecture_unanswered_count: 0,
            network: [],
            selection: []
        });

        const fetchData = async (bonusId) => {
            models.isLoading = true;
            try {
                const response = await axios.get(`/api/v2/client_account/bonuses/${bonusId}/show_detail`, {
                    headers: store.state.auth,
                    'Content-Type': 'application/json'
                });
                models.infomation = response.data.summary.infomation;
                models.eras = response.data.summary.eras;
                models.genders = response.data.summary.genders;
                models.sense_codes = response.data.summary.sense_codes;
                models.sense_code_unanswered_count = response.data.summary.sense_code_unanswered_count;
                models.prefectures = response.data.summary.prefectures;
                models.prefecture_unanswered_count = response.data.summary.prefecture_unanswered_count;
                models.selection = response.data.selection;
                models.network = response.data.network;
            } catch (error) {
                console.error('データの取得に失敗しました:', error);
            }
            models.isLoading = false;

            window.setTimeout(() => {
                isAnimated.value = true;
            }, 3000);
        };

        /**
         * adjustPrintPages - 1ページあたりの印刷コンテンツの調整
         *
         * @function
         * @param {Document} doc - 印刷対象の `Document` オブジェクト
         * @param {number} height - 1ページあたりの高さ（px単位）
         * @param {number} [margin=20] - 各要素間の余白（px単位、デフォルト20）
         *
         * @description
         * - 指定された高さを超えないように、各グラフ要素をページごとに分割
         * - 必要に応じて、再配置してページ分割を調整する
         */
        const adjustPrintPages = (doc, height, margin = 20) => {
            const graphItems = [...doc.querySelectorAll('[data-type]')];
            const content = doc.getElementById('content');

            const {current, result} = graphItems.reduce((groups, element) => {
                const {clientHeight} = element.firstElementChild;
                const currentSummary = groups.summary + clientHeight + margin;

                // 要素 + 余白の合計が、1ページあたりの高さを超える場合、その時点までの要素を1つの親要素にまとめる
                if (currentSummary >= height) {
                    groups.result.push([...groups.current]);
                    groups.current = [element.firstElementChild];
                    groups.summary = clientHeight + margin;

                    return groups;
                }

                // 要素を追加
                groups.current.push(element.firstElementChild);

                // 現在の高さを更新
                groups.summary = currentSummary;

                return groups;
            }, {summary: 0, current: [], result: []});

            graphItems.forEach((element) => {
                element.remove();
            });

            [...result, current].forEach((elements) => {
                const container = doc.createElement('div');

                if (!elements.length) {
                    return;
                }

                container.classList.add('section');
                elements.forEach((element) => container.appendChild(element));
                content.appendChild(container);
            });
        };

        /**
         * printPage - 印刷処理
         *
         * @description
         * - 印刷フラグを `true` に設定し、描画完了後に印刷ウィンドウを開く
         * - 指定されたHTML要素の内容をPDF形式で保存する
         */
        const printPage = () => {
            // 印刷中の場合は何もしない
            if (isPrinting.value) {
                return;
            }

            const today = new Date();
            const formattedDate = today.getFullYear().toString()
                + (today.getMonth() + 1).toString().padStart(2, '0')
                + today.getDate().toString().padStart(2, '0');

            // 印刷フラグを更新（印刷中）
            isPrinting.value = true;

            // フラグ更新による描画を待ってから印刷を実行
            nextTick(() => {
                const windowWidth = 794;
                const windowHeight = 1123;
                const baseElement = document.querySelector('.t-main__graph');
                const styles = [...document.head.querySelectorAll('style,link[rel="stylesheet"]')].map(({outerHTML}) => outerHTML).join('');
                const printWindow = window.open('', 'print', `width=${windowWidth},height=${windowHeight}`);
                const {document: doc} = printWindow.window;
                const fragment = doc.createDocumentFragment();

                const potions = {
                    margin: 3, // PDF内の余白
                    filename: `${surveyId}_${models.infomation[0].description}_サマリ_${formattedDate}`, // ダウンロードされるPDFのファイル名
                    image: {type: 'png', quality: 1}, // 画像の品質
                    html2canvas: {scale: 2}, // HTMLをキャンバスに変換する際のスケール
                    jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'} // PDFの設定
                };

                // 見出しを除去し、<canvas> を base64エンコード画像に変換し、<img>に置換
                [...baseElement.children].slice(1).forEach((child, index) => {
                    const element = doc.importNode(child, true);
                    const sourceCanvases = child.querySelectorAll('canvas');
                    const replaceCanvases = element.querySelectorAll('canvas');
                    const originClass = element.className.split(' ').at(0).replace('t-main__', '');

                    // <canvas> の base64エンコード
                    [...sourceCanvases].forEach((canvas, idx) => {
                        replaceCanvases[idx].outerHTML = `<img src="${canvas.toDataURL('image/png')}" alt="" />`;
                    });

                    // フロントページの作成
                    if (index === 0) {
                        const front = doc.createElement('div');

                        front.classList.add('front-page');
                        front.insertAdjacentHTML('afterbegin', `<img src="${window.location.origin}/img/bg-pdf-front.png">`);
                        front.appendChild(element.firstElementChild);

                        fragment.appendChild(front);

                        return;
                    }

                    // オリジナルのクラス名をdata-class属性に格納
                    element.dataset.class = originClass;
                    element.setAttribute('class', 'section');
                    fragment.appendChild(element);
                });

                // 印刷用windowにHTMLを描画
                doc.write(printPageHTML);
                doc.body.setAttribute('style', 'min-width: auto; max-width: none; background: none');
                doc.head.insertAdjacentHTML('afterbegin', styles);
                doc.getElementById('content').appendChild(fragment);

                // 1ページあたりのコンテンツ数を調整
                adjustPrintPages(doc, windowHeight - 90);

                // フッターを挿入
                [...doc.getElementById('content').children].forEach((element, index) => {
                    element.insertAdjacentHTML(
                        'beforeend',
                        index === 0
                            ? '<div class="footer">賢い買物スキルの⽣活者アンケート キャッシュビーデータ株式会社<br>　</div>'
                            : `<div class="footer">賢い買物スキルの⽣活者アンケート キャッシュビーデータ株式会社<br>${index - 1}</div>`
                    );
                });

                // PDFのレンダリングとダウンロード処理
                html2pdf()
                    .set(potions)
                    .from(doc.getElementById('content'))
                    .save()
                    .then(() => {
                        isPrinting.value = false;

                        window.setTimeout(() => {
                            printWindow.close();
                        }, 500);
                    });
            });
        };

        onMounted(() => {
            fetchData(surveyId);
        });

        return {
            isPrinting, isAnimated, models, breadcrumbs, printPage
        };
    }
});
</script>

<style lang="scss" scoped></style>
