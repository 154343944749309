<template>
    <main class="t-main" :class="{'is-menu-expanded': isMenuExpanded}">
        <div class="t-main__heading">
            <h1 class="t-main__title">賢い買物スキルの生活者アンケート 一覧</h1>
            <button-group direction="column">
                <standard-button appearance="primary" @click="$router.push({name: 'ClientSurveysNew'})">
                    <template v-slot:before><icon name="Add" /></template>
                    新規アンケート登録
                </standard-button>
                <standard-button appearance="secondary" @click="$emit('openBlank', '/pdf/賢い買物スキルの生活者アンケートのご紹介_20241211.pdf')">
                    <template v-slot:before><icon name="Download" /></template>
                    アンケート結果ファイルの説明
                </standard-button>
            </button-group>
        </div>

        <div class="t-main__utils">
            <tab-list v-bind="tabs" :current-index="tabs.currentIndex.value">
                <template v-slot:tab="slotProps">
                    <tab v-bind="slotProps.item" :is-external-link="false">{{slotProps.item.label}}</tab>
                </template>
            </tab-list>
        </div>

        <tables v-bind="surveyTableData" :is-loading="surveyTableData.isLoading.value" :body-rows="surveyTableData.bodyRows.value">
            <template v-slot:table-header="tableHeaderProps">
                <table-head v-bind="tableHeaderProps.rows">
                    <template v-slot:header-row="tableRowProps">
                        <table-row v-bind="tableRowProps">
                            <template v-slot:cell-0="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-1="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-2="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-3="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-4="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-5="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                        </table-row>
                    </template>
                </table-head>
            </template>

            <template v-slot:table-body="tableBodyProps">
                <table-body v-bind="tableBodyProps.rows">
                    <template v-slot:body-row="tableRowProps">
                        <table-row v-bind="tableRowProps">
                            <template v-slot:cell-0="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell" :clamp="1">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-1="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-2="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-3="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-4="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-5="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">
                                    <menus :id="`action-menu-${tableCellProps.cell.content}`" v-bind="actionMenu" placement="auto-right" :is-expanded="actionMenu.isExpanded.value && actionMenu.currentId.value === tableCellProps.cell.content">
                                        <template v-slot:menu-trigger="menuTriggerProps">
                                            <icon-button
                                                v-bind="menuTriggerProps.trigger"
                                                title="メニュー"
                                                :ref="`menuTrigger-${tableCellProps.cell.content}`"
                                                @click.stop.prevent="$emit('openMenu', actionMenu, tableCellProps.cell.content)"
                                            ><icon name="More" /></icon-button>
                                        </template>

                                        <template v-slot:menu-list="menuListProps">
                                            <menu-list v-bind="menuListProps.menus" @close="$emit('closeMenu', actionMenu)">
                                                <template v-slot:menu-item="menuItemProps">
                                                    <menu-item
                                                        v-bind="menuItemProps.item"
                                                        @click.stop="
                                                            $emit(menuItemProps.item.emitEvent, alertModal, tableCellProps.cell.content) &
                                                            $emit('closeMenu', actionMenu)
                                                        "
                                                    >
                                                        <template v-if="menuItemProps.item.beforeIcon" v-slot:before><icon :name="menuItemProps.item.beforeIcon" /></template>
                                                        {{menuItemProps.item.label}}
                                                        <template v-if="menuItemProps.item.afterIcon" v-slot:after><icon :name="menuItemProps.item.afterIcon" /></template>
                                                    </menu-item>
                                                </template>
                                            </menu-list>
                                        </template>
                                    </menus>
                                </table-cell>
                            </template>
                        </table-row>
                    </template>
                </table-body>
            </template>

            <template v-slot:empty>
                <empty-message v-bind="surveyTableData.emptyMessage"/>
            </template>

            <template v-slot:footer v-if="surveyTableData.bodyRows.value.length">
                <p v-if="surveyTableData.pagination.pages.value.length">{{surveyTableData.pagination.pages.value.slice(-1)[0]}}ページ中 {{surveyTableData.pagination.currentPage.value}}ページ</p>
                <pagination :current-page="surveyTableData.pagination.currentPage.value" :pages="surveyTableData.pagination.pages.value">
                    <template v-slot:item="pagitaionItemProps">
                        <pagination-item
                            :is-current="pagitaionItemProps.item.isCurrent"
                            :disabled="pagitaionItemProps.item.disabled"
                            @clicked="$router.push({query: {...Object.assign({}, $route.query), ...{page: pagitaionItemProps.item.page}}})"
                        >
                            <template v-if="pagitaionItemProps.item.icon" v-slot:icon><icon :name="pagitaionItemProps.item.icon" /></template>
                            {{pagitaionItemProps.item.label}}
                        </pagination-item>
                    </template>
                </pagination>
            </template>
        </tables>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import ButtonGroup, {StandardButton, IconButton} from '@/components/01_Atoms/Buttons';
import Icon from '@/components/01_Atoms/Icons';
import Tables, {
    TableHead, TableBody, TableRow, TableCell
} from '@/components/01_Atoms/Tables';
import Menus, {MenuItem, MenuList} from '@/components/01_Atoms/Menus';
import {Tab, TabList} from '@/components/01_Atoms/Tabs';
import Pagination, {PaginationItem} from '@/components/01_Atoms/Paginations';
import {EmptyMessage} from '@/components/01_Atoms/Messages';

export default defineComponent({
    inheritAttrs: false,
    components: {
        Tab, TabList, ButtonGroup, StandardButton, IconButton, Icon, Tables, TableHead, TableBody, TableRow, TableCell, Menus, MenuItem, MenuList, Pagination, PaginationItem, EmptyMessage
    },
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        tabs: {
            type: Object,
            default: () => ({})
        },
        surveyTableData: {
            type: Object,
            default: () => ({})
        },
        actionMenu: {
            type: Object,
            default: () => ({})
        },
        alertModal: {
            type: Object,
            default: () => ({})
        },
        pagination: {
            type: Object,
            default: () => ({})
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 0  24px 24px 98px;
    background: #F2F6F9;
    transition: padding-left .3s ease 0s;

    &.is-menu-expanded {
        padding-left: 234px;
    }

    @at-root {
        .t-main__heading {
            display: flex;
            justify-content: space-between;
            padding: 48px 0 24px 0;

            > :last-child {
                flex: 0 0 auto;
            }
        }

        .t-main__utils {
            padding-bottom: 48px;
            margin: auto -24px;
        }

        .t-main__title {
            font-size: 3.6rem;
            line-height: (44 / 36);
            font-weight: bold;
            color: var.$color-text-high;
        }
    }
}
</style>
