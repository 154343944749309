<template>
    <div ref="root" class="a-graph-band">
        <div class="title">{{title}}</div>
        <canvas ref="chartCanvas"></canvas>
        <div :class="unansweredCount > 0 ? 'unanswered-container' : 'unanswered-container unanswered-container-is-button'">
            <p v-if="unansweredCount > 0" class="unanswered-highlight">
                ※{{ title === '都道府県' ? '不明' : '未回答' }} {{ unansweredCount }}件
            </p>
        </div>
    </div>
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, onMounted, watch
} from 'vue';
import {
    Chart, BarController, BarElement, CategoryScale, LinearScale, Tooltip, Title
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip, Title, ChartDataLabels);

export default defineComponent({
    inheritAttrs: false,
    props: {
        spacing: {
            type: Number,
            default: 50
        },
        data: {
            type: Array
        },
        title: {
            type: String
        },
        valueLabel: {
            type: String
        },
        unansweredCount: {
            type: Number
        }
    },
    setup(props, {attrs}) {
        const root = ref(null);
        const chartCanvas = ref(null);
        const DEFAULT_DISPLAY_COUNT = 15;
        const VERTICAL_HEIGHT = 40;
        const isExpanded = ref(true);
        let chartInstance = null;

        const id = attrs.id || `band-graph-${Math.random().toString(36).slice(-8)}`;

        const renderChart = () => {
            if (chartInstance) {
                chartInstance.destroy();
            }

            const canvasElement = chartCanvas.value;
            const dataToShow = isExpanded.value ? props.data : props.data.slice(0, DEFAULT_DISPLAY_COUNT);
            canvasElement.width = 1000;

            // データ件数に合わせて縦幅を調整
            const baseHeight = 350;
            const increment = props.spacing;

            if (!isExpanded.value && props.data.length > DEFAULT_DISPLAY_COUNT) {
                // 省略表示の場合はDEFAULT_DISPLAY_COUNTの数まで
                canvasElement.height = DEFAULT_DISPLAY_COUNT * VERTICAL_HEIGHT;
            } else {
                // 5個以下の場合は一律でbaseHeightに
                if (props.data.length <= 5) {
                    canvasElement.height = baseHeight;
                }

                // 5個以上の場合は6個単位で縦幅を増加
                if (props.data.length > 5) {
                    const adjustedLength = Math.max(props.data.length - 6, 0);
                    canvasElement.height = baseHeight + adjustedLength * Math.min(increment, 120);
                }
            }

            chartInstance = new Chart(chartCanvas.value, {
                type: 'bar',
                data: {
                    labels: dataToShow.map((item) => item.label),
                    datasets: [{
                        label: props.valueLabel,
                        data: dataToShow.map((item) => item.value),
                        backgroundColor: 'rgba(54, 162, 235, 0.6)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1,
                        barThickness: 20
                    }]
                },
                options: {
                    animations: false,
                    indexAxis: 'y',
                    responsive: true,
                    maintainAspectRatio: true,
                    layout: {
                        padding: {
                            right: 90
                        }
                    },
                    scales: {
                        x: {
                            beginAtZero: true,
                            title: {
                                display: false
                            },
                            grid: {
                                display: false
                            },
                            ticks: {
                                color: '#000',
                                font: {
                                    size: 16,
                                    weight: 'bold'
                                }
                            }
                        },
                        y: {
                            grid: {
                                display: false
                            },
                            ticks: {
                                stepSize: 0,
                                color: '#000',
                                autoSkip: false,
                                padding: 0,
                                font: {
                                    size: 14,
                                    weight: 'bold'
                                },
                                callback(index) {
                                    // 10文字を超える場合は「...」で省略
                                    const {label} = dataToShow[index];
                                    const chunkSize = 34;

                                    return Array.from(
                                        {length: Math.ceil(label.length / chunkSize)},
                                        (_, i) => label.slice(i * chunkSize, i * chunkSize + chunkSize)
                                    );
                                }
                            }
                        }
                    },
                    plugins: {
                        tooltip: {
                            enabled: true
                        },
                        datalabels: {
                            anchor: 'end',
                            align: 'right',
                            formatter: (value, context) => {
                                const {data} = context.dataset;
                                const total = data.reduce((acc, val) => acc + val, 0);
                                const percentage = total ? ((value / total) * 100).toFixed(1) : '0.0';

                                return `${value} (${percentage}%)`;
                            },
                            color: '#000',
                            font: {
                                size: 14,
                                weight: 'bold'
                            }
                        }
                    }
                }
            });
        };

        const toggleDataDisplay = (event) => {
            isExpanded.value = !isExpanded.value;
            renderChart();
            const target = event.currentTarget.previousElementSibling.previousElementSibling;
            if (target) {
                target.scrollIntoView({
                    block: 'start'
                });
            }
        };

        onMounted(() => {
            renderChart();
        });

        watch(
            () => [props.data, props.spacing],
            () => {
                renderChart();
            }
        );

        return {
            id,
            root,
            chartCanvas,
            toggleDataDisplay,
            isExpanded,
            DEFAULT_DISPLAY_COUNT
        };
    }
});
</script>

<style lang="scss" scoped>
.a-graph-band {
    width: 90%;
    margin: 0 auto;
    position: relative;
    padding: 0 40px 60px 40px;
}

.load-more-btn {
    z-index: 10;
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
    margin-top: 30px;
}

.load-more-btn:hover {
    background-color: #0056b3;
}

.load-more-btn:focus {
    outline: none;
}

.load-more-btn:active {
    transform: scale(0.95);
    transition: transform 0.1s ease;
}

.load-more-btn.expanded {
    background-color: #28a745;
}

.unanswered-container {
    position: absolute;
    right: 1cm;
    text-align: right;
    width: 100%;
    margin: 0px 10px;
}

.unanswered-container-is-button {
    bottom: 0cm;
}

.unanswered-highlight {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: bold;
    color: #808080;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px 0;
}
.title {
    font-size: 22px;
    font-weight: bold;
    color: black;
    text-align: center;
}
</style>
